import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
import store from '../store';
import { i18n } from '../plugins/i18n.js';
// import App from '../App.vue'

Vue.use(VueRouter);

const scrollBehavior = function (to, from, savedPosition) {
  if (savedPosition) {
    // savedPosition is only available for popstate navigations.
    return savedPosition
  } else {
    const position = {}

    // scroll to anchor by returning the selector
    // console.log(to.hash)
    if (to.hash) {
        position.selector = to.hash
        // console.log(position)
        // specify offset of the element
        if (to.hash === '#our_stores') {
          position.offset = { y: 100 }
          // return position
        }

        // console.log(document.querySelector(to.hash))
        // return position
        // bypass #1number check
        if (/^#\d/.test(to.hash) || document.querySelector(to.hash)) {
          // console.log('azazaza')
          return position
        }

        // if the returned position is falsy or an empty object,
        // will retain current scroll position.

        // return false
    }

    return new Promise(resolve => {
      // check if any matched route config has meta that requires scrolling to top
      // console.log('to.matched', to.matched)
      const scrollToTop = to.matched.some(m => m.meta.scrollToTop)
      const scrolFromOtherPages = to.matched.some(m => m.meta.scrolFromOtherPages)
      const fromName = from.matched.some(m => m.name)
      const toName = to.matched.some(m => m.name)
      if (scrollToTop && !scrolFromOtherPages || (scrolFromOtherPages && fromName !== toName)) {
        // coords will be used if no selector is provided,
        // or if the selector didn't match any element.
        position.x = 0
        position.y = 0
      }
      // console.log(123)
      // wait for the out transition to complete (if necessary)
      this.app.$root.$once('triggerScroll', () => {
        // if the resolved position is falsy or an empty object,
        // will retain current scroll position.
        resolve(position)
      })
    })
  }
}

const Router = new VueRouter({
  mode: 'history',
  base: __dirname,
  scrollBehavior,
  routes
});

Router.beforeEach((to, from, next) => {
  let lang = to.params.lang
  // document.title = i18n.t(to.meta.title) || 'Zodchiy.net.ua'

  if (!lang || !['ru', 'uk'].includes(lang)) {
    lang = store.state.language.language || 'uk'
  }

  // window.scrollTo(0, 0)
  if (store.state.language.language && (store.state.language.language !== i18n.locale || store.state.language.language != lang)) {
    i18n.locale = store.state.language.language;
    // console.log(1)
    next();
  } else if (!store.state.language.language) {
    store.dispatch('language/setLanguage', navigator.languages)
      .then(() => {
        i18n.locale = store.state.language.language;
        next();
        // console.log(2)
      });
  } else {
    // console.log(3)
    next();
  }
});

export default Router;